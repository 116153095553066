import React, { useState } from "react"
import Footer from "./Footer"
import MobileMenu from "./MobileMenu"
import Navbar from './Navbar'
import { Box, ColorModeProvider, ColorModeScript } from "@chakra-ui/react"
import { Helmet } from "react-helmet"
import { ChakraProvider } from "@chakra-ui/provider"
import theme from '../../theme'

const Layout = ({ title, colorMode, children }) => {
  const [showMobileMenu, setMobileMenu] = useState(false)
  const toggleMobileMenu = () => {
    setMobileMenu(!showMobileMenu)
    console.log('Mobilemenu now: ' + showMobileMenu)
  }

  return (
    <>

      <Helmet
        defaultTitle="Majanko"
        titleTemplate="%s | Majanko"

      >
        <title>{title}</title>
      </Helmet>
      <ChakraProvider theme={theme}>
          <Box position='absolute'>
            <Navbar
              mapKey='nav-link'
              links={[
                {
                  linkText: 'Home',
                  link: '/',
                  isExternal: false,
                },
              ]}
              parentCallback={toggleMobileMenu}
              colorMode={colorMode}
            />
          </Box>

          <MobileMenu
            parentCallback={toggleMobileMenu}
            showMobileMenu={showMobileMenu}
            colorMode={colorMode}
          ></MobileMenu>



          <main>
            {children}
          </main>

          <Footer
            bgColor={'brand.space'}
            textColor={'white'}
            mapKey='nav-links-footer'
            colorMode={colorMode}
            links={[
              {
                linkText: 'Home',
                link: '/',
                isExternal: false,
              },
              {
                linkText: 'Portfolio',
                link: '/portfolio',
                isExternal: false,
              },
              {
                linkText: 'Targets',
                link: '/targets',
                isExternal: false,
              },
              {
                linkText: 'Investors',
                link: '/investors',
                isExternal: false,
              },
              {
                linkText: 'Team',
                link: '/team',
                isExternal: false,
              },
              {
                linkText: 'About us',
                link: '/aboutus',
                isExternal: false,
              },

            ]}

          />
      </ChakraProvider>
    </>
  )
}

export default Layout
