import React from 'react'
import { motion } from 'framer-motion'
import { Box, useColorMode, useColorModeValue } from '@chakra-ui/react'
import RouterLink from '../../componentLibrary/components/RouterLink';
import { baseTheme } from '../../theme';
import GImage from '../GImage';

const MotionBox = motion(Box);

export default function MobileMenu({ showMobileMenu, parentCallback, colorMode }) {
    const dualColor = colorMode == 'dark' ? 'brand.space' : 'brand.sand'
    const dualColorRev = colorMode == 'dark' ? 'brand.pearl' : 'brand.space'
    return (
        <MotionBox
            position='fixed'
            zIndex='999'
            top='0'
            left='0'
            h='100vh'
            w='400px'
            display='flex'
            flexDirection='column'
            bg={dualColorRev}
            initial={{
                x: -400,
            }}
            animate={{
                x: showMobileMenu ? 0 : -400,
                transition: {
                    duration: 0.3,
                    type: 'linear'
                }
            }}
        >
            <Box _hover={{ cursor: 'pointer' }} onClick={parentCallback} p={8}>
                <svg width="32" height="32" viewBox="0 0 40 40" fill="none">
                    <path d="M1 39L39 1M39 39L1 1" stroke={colorMode === 'light' ? baseTheme.colors.brand.pearl : baseTheme.colors.brand.space} />
                </svg>
            </Box>
            <Box px={8} pt={8} display='flex' flexDirection='column' gap={3} onClick={parentCallback}>
                <RouterLink color={dualColor} textStyle='h1' fontFamily='acumin-pro-wide' fontWeight='bold' isExternal={false} to='/'>Home</RouterLink>
                <RouterLink color={dualColor} textStyle='h1' fontFamily='acumin-pro-wide' fontWeight='bold' isExternal={false} to='/music'>EP</RouterLink>
                <RouterLink color={dualColor} textStyle='h1' fontFamily='acumin-pro-wide' fontWeight='bold' isExternal={false} to='/about'>About</RouterLink>
                <RouterLink color={dualColor} textStyle='h1' fontFamily='acumin-pro-wide' fontWeight='bold' isExternal={false} to='/contact'>Kontakt</RouterLink>
            </Box>
            <Box px={8} mt={8}>
                <RouterLink color={dualColor} textStyle='pBig' fontFamily='Monarcha' fontWeight='bold' isExternal={false} to='/impressum' mb={2}>Impressum</RouterLink>
                <RouterLink color={dualColor} textStyle='pBig' fontFamily='Monarcha' fontWeight='bold' isExternal={false} to='/datenschutz'>Datenschutz</RouterLink>
            </Box>
            <Box p={8} pb={16} h='100%' display='flex' flexDirection={'column'} justifyContent='end'>
                <RouterLink flexShrink='0' isExternal={false} to='/'>
                    {colorMode === 'light' ? (
                        <GImage w='50px' src='majanko_logo_signet_white.svg' alt='/' />
                    ) : (
                        <GImage w='50px' src='majanko_logo_signet.svg' alt='/' />
                    )}
                </RouterLink>
            </Box>

        </MotionBox>
    )
}
