import { extendTheme } from '@chakra-ui/react'

const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
}

export const baseTheme = {
  config,
  styles: {
    global: {
      'html, body': {
        color: 'brand.space',
      },
    },
  },
  sizes: {
      xxl: '1600px',
      xl: '1440px',
  },
  breakpoints: {
    xxl: '1600px',
    xl: '1440px',
  },
  letterSpacings: {
    tighter: "-0.05em",
    tight: "-0.025em",
    normal: "0",
    wide: "0.025em",
    wider: "0.05em",
    widest: "0.1em",
  },
  textStyles: {
    h00: {
      fontSize: {lg: '78px', base: '48px'},
      lineHeight:  {lg: '78px', base: '48px'},
      fontWeight: 'bold'
    },
    h1: {
      fontSize: {md: '48px', base: '32px'},
      lineHeight: {md: '48px', base: '32px'},
      fontWeight: 'bold'
    },
    h2: {
      fontSize: '32px',
      lineHeight: '32px',
      fontWeight: 'bold'
    },
    h3: {
      fontSize: '28px',
      lineHeight: '28px',
      fontWeight: 'bold'
    },
    h4: {
      fontSize: '24px',
      lineHeight: '24px',
      fontWeight: 'bold'
    },
    pBig: {
      fontSize: '18px'
    },
    p: {
      fontSize: '16px',
      lineHeight: '22px'
    }
  },
  components: {
    Button:{

    },
    Container: {
      variants: {
        layoutContainer: {
          maxWidth: 'xl',
          px: {sm: 8, base: 4}
        }
      }
    },
    Link: {
      baseStyle: {
        _hover: {
          textDecoration: 'none',
        }
      }
    },
  },
  colors: {
    brand: {
      primary: '#3C7AA8',
      blue: '#4C72F9',
      space: '#000011',
      sand: '#E9E6DE',
      pearl: '#F7F5F2',
      gray: '#E6E6E9'
    }
  }
}

export default extendTheme(baseTheme)