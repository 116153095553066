import React from 'react';
import { Box, Container, Text, useColorMode, useColorModeValue } from '@chakra-ui/react';
import GImage from '../GImage';
import RouterLink from '../../componentLibrary/components/RouterLink';
export default function Footer({
  colorMode
}) {
  const dualColor = colorMode == 'light' ? 'brand.space' : 'brand.sand'
  const dualColorReverse = colorMode == 'light' ? 'brand.pearl' : 'brand.space'
  return (
    <Container
      as='footer'
      w='100%'
      maxW='100%'
      bg={dualColor}
      py={24}
    >
      <Container
        variant='layoutContainer'
        display='flex'
        justifyContent={'space-between'}
        alignItems='center'
        px={{ md: 16, base: 8 }}
      >
        {colorMode == 'light' ? <GImage src='majanko_logo_signet_white.svg' alt='/' w={{ md: 'auto', base: '50px' }} /> : <GImage src='majanko_logo_signet.svg' alt='/' w={{ md: 'auto', base: '50px' }} />}
        {colorMode == 'light' ? <GImage src='majanko_logo_lettering_white.svg' alt='/' h={{ md: 'auto', base: '22px' }} /> : <GImage src='majanko_logo_lettering.svg' alt='/' h={{ md: 'auto', base: '22px' }} />}
      </Container>
      <Container variant='layoutContainer' my={16}>
        <Box h='1px' w='100%' bg={dualColorReverse}></Box>
      </Container>
      <Container
        variant='layoutContainer'
        display='flex'
        justifyContent={'space-between'}
        mb={16}
        px={{ md: 16, base: 8 }}
      >
        <Box>
          <RouterLink color={dualColorReverse} textStyle='pBig' fontFamily='acumin-pro-wide' fontWeight='bold' isExternal={false} to='/'>Home</RouterLink>
          <RouterLink color={dualColorReverse} textStyle='pBig' fontFamily='acumin-pro-wide' fontWeight='bold' isExternal={false} to='/music'>EP</RouterLink>
          <RouterLink color={dualColorReverse} textStyle='pBig' fontFamily='acumin-pro-wide' fontWeight='bold' isExternal={false} to='/contact'>Kontakt</RouterLink>
          <RouterLink color={dualColorReverse} textStyle='pBig' fontFamily='acumin-pro-wide' fontWeight='bold' isExternal={false} to='/about'>About</RouterLink>
          <RouterLink color={dualColorReverse} textStyle='pBig' fontFamily='acumin-pro-wide' fontWeight='bold' isExternal={false} to='/datenschutz'>Datenschutz</RouterLink>
          <RouterLink color={dualColorReverse} textStyle='pBig' fontFamily='acumin-pro-wide' fontWeight='bold' isExternal={false} to='/impressum'>Impressum</RouterLink>
        </Box>
        <Box display='flex' justifyContent={'flex-end'} gap={4}>
          <RouterLink isExternal={true} link='https://open.spotify.com/artist/6OeGyPRy3eL7T2IS87A2lL?si=4HCI9WcFTCerqrz9e5p1lA'>{colorMode == 'light' ? <GImage h='24px' src='icon_spotify_white.svg' alt='/' /> : <GImage h='24px' src='icon_spotify.svg' alt='/' />}</RouterLink>
          <RouterLink isExternal={true} link='https://www.tiktok.com/@majanko.music'>{colorMode == 'light' ? <GImage h='24px' src='icon_tiktok_white.svg' alt='/' /> : <GImage h='24px' src='icon_tiktok.svg' alt='/' />}</RouterLink>
          <RouterLink isExternal={true} link='https://instagram.com/majanko.music?igshid=YmMyMTA2M2Y='>{colorMode == 'light' ? <GImage h='24px' src='icon_insta_white.svg' alt='/' /> : <GImage h='24px' src='icon_insta.svg' alt='/' />}</RouterLink>
          <RouterLink isExternal={true} link='https://www.facebook.com/majanko.music'>{colorMode == 'light' ? <GImage h='24px' src='icon_fb_white.svg' alt='/' /> : <GImage h='24px' src='icon_fb.svg' alt='/' />}</RouterLink>
        </Box>
      </Container>
    </Container>

  );
}