import { Box, Container, Flex } from '@chakra-ui/react';
import React from 'react';
import GImage from '../GImage';
import RouterLink from '../../componentLibrary/components/RouterLink'

export default function Navbar({ parentCallback, colorMode }) {
  const dualColor = colorMode == 'light' ? 'brand.space' : 'brand.pearl'
  const dualColorReverse = colorMode == 'light' ? 'brand.pearl' : 'brand.space'
  return (
    <Box as='nav' position='fixed' top='0' left='0' zIndex={99} w='100vw' py={8} bg={dualColorReverse}>
      <Container variant='layoutContainer' display='grid' gridTemplateColumns={'repeat(3, 1fr)'} alignItems='center' px={16}>
        <Flex gap={8} display={{ lg: 'flex', base: 'none' }}>
          <RouterLink color={dualColor} textStyle='pBig' fontFamily='acumin-pro-wide' fontWeight='bold' isExternal={false} to='/music'>EP</RouterLink>
          <RouterLink color={dualColor} textStyle='pBig' fontFamily='acumin-pro-wide' fontWeight='bold' isExternal={false} to='/contact'>Kontakt</RouterLink>
          <RouterLink color={dualColor} textStyle='pBig' fontFamily='acumin-pro-wide' fontWeight='bold' isExternal={false} to='/about'>About</RouterLink>
        </Flex>

        <Box onClick={parentCallback} display={{ lg: 'none', base: 'flex' }} flexDirection={'column'} gap={2} _hover={{ cursor: 'pointer' }}>
          <Box h='1px' w='1.5rem' bg={dualColor}></Box>
          <Box h='1px' w='1.5rem' bg={dualColor}></Box>
          <Box h='1px' w='1.5rem' bg={dualColor}></Box>
        </Box>

        <Box mx='auto'>
          <RouterLink flexShrink='0' isExternal={false} to='/'>
            {colorMode === 'light' ? (
              <GImage src='majanko_logo_lettering.svg' alt='Majanko Logo Schriftzug' />
            ) : (
              <GImage src='majanko_logo_lettering_white.svg' alt='Majanko Logo Schriftzug' />
            )}
          </RouterLink>
        </Box>
        <Flex gap={4} justifyContent='flex-end' display={{ md: 'flex', base: 'none' }}>
          {colorMode === 'light' ? <RouterLink isExternal={true} link='https://instagram.com/majanko.music?igshid=YmMyMTA2M2Y='><GImage w='24px' h='24px' src='icon_insta.svg' alt='/' /></RouterLink> : <RouterLink isExternal={true} link='https://instagram.com/majanko.music?igshid=YmMyMTA2M2Y='><GImage w='24px' h='24px' src='icon_insta_white.svg' alt='/' /></RouterLink>}
          {colorMode === 'light' ? <RouterLink isExternal={true} link='https://www.tiktok.com/@majanko.music'><GImage w='24px' h='24px' src='icon_tiktok.svg' alt='/' /></RouterLink> : <RouterLink isExternal={true} link='https://www.tiktok.com/@majanko.music'><GImage w='24px' h='24px' src='icon_tiktok_white.svg' /></RouterLink>}
          {colorMode === 'light' ? <RouterLink isExternal={true} link='https://www.facebook.com/majanko.music'><GImage w='24px' h='24px' src='icon_fb.svg' alt='/' /></RouterLink> : <RouterLink isExternal={true} link='https://www.facebook.com/majanko.music'><GImage w='24px' h='24px' src='icon_fb_white.svg' /></RouterLink>}
          {colorMode === 'light' ? <RouterLink isExternal={true} link='https://open.spotify.com/artist/6OeGyPRy3eL7T2IS87A2lL?si=4HCI9WcFTCerqrz9e5p1lA'><GImage w='24px' h='24px' src='icon_spotify.svg' alt='/' /></RouterLink> : <RouterLink isExternal={true} link='https://open.spotify.com/artist/6OeGyPRy3eL7T2IS87A2lL?si=4HCI9WcFTCerqrz9e5p1lA'><GImage w='24px' h='24px' src='icon_spotify_white.svg' alt='/' /></RouterLink>}
        </Flex>
      </Container>
    </Box>
  )
}
