import { Link as ChakraLink, Box } from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby';
import { motion } from 'framer-motion';
import React from 'react';

export default function RouterLink({ link, isExternal, children, variants, variant, ...restProps }) {
  const MotionBox = motion(Box);
  return (
    <MotionBox
      variants={variants}
    >
      <ChakraLink
        _focus={{outline: 'none'}}
        activeStyle={{borderBottom: '1px solid'}}
        {...(isExternal ? { href: link } : { as: GatsbyLink, to: link })}
        {...restProps}
      >
        {children}
      </ChakraLink>
    </MotionBox>
  );
}