import React, {useState} from 'react'
import { Box } from "@chakra-ui/react"
import { motion } from "framer-motion"
import GImage from "./GImage"

const MotionBox = motion(Box)

const ParallaxImgInner = ({
  parallaxStart, 
  parallaxEnd,
  parallaxOffsetStart, 
  parallaxOffsetEnd,
  imgSrc, 
  alt,
  AnimationValue,
  SetScrollProgressKeyPointStartVpBottom, 
  ...boxProps
}) => {
  
  const SP = SetScrollProgressKeyPointStartVpBottom
  const parallaxFactor = AnimationValue([SP(0), SP(1, true)], [parallaxOffsetStart, parallaxOffsetEnd])
  let parallaxOvershoot = 0
  if(parallaxOffsetEnd > parallaxOffsetStart){
    parallaxOvershoot = parallaxOffsetEnd - parallaxOffsetStart
  }else{
    parallaxOvershoot = parallaxOffsetStart - parallaxOffsetEnd
  }

  return (
    <Box 
      overflow='hidden' 
      position='relative'
      {...boxProps}
    >
      <MotionBox 
        position='absolute' 
        top='50%' 
        left='50%'
        transform='translate(-50%, -50%)' 
        w='100%' 
        h={`calc(100% + ${parallaxOvershoot}px)`}

      >
        <MotionBox 
          style={{ y: parallaxFactor }} 
          w='100%' 
          h='100%'
          transform='translateZ(0)'
        >
          <GImage
            src={imgSrc} 
            alt={alt}
            w='100%' 
            h='100%'
          />
        </MotionBox>
      </MotionBox>
    </Box>
  )
}

export default ParallaxImgInner