/*  --------------------------------------------------

Hook useRefScrollProgress

Funktion: Was macht der Hook? 
    - Spielt die prozentualen Start- und Endwerte, relativ zur Dokumenten-Scrolllänge, aus, wenn ein ref übergeben wird.
    - Die Start- und Endwerte können widerum genutzt werden, um z.B. Animationen an den 
    Scrollfortschritts eines spezifischen Blocks zu verknüpfen.

Beispiel: 
  -  const { start, end } = useRefScrollProgress(myRef);

----------------------------------------------------- */ 

/*
  Takes an optional component ref (or returns a new one)
  and returns the ref, the scroll `start` and `end` percentages
  that are relative to the total document progress.
*/

import { useState, useLayoutEffect } from "react";

function useRefScrollProgress(inputRef) {
    const ref = inputRef;
  
    const [startVpTop, setStart] = useState(null);
    const [startVpBottom, setStartVpBottom] = useState(null);
    const [endVpTop, setEnd] = useState(null);
    const [scaledVp, setScaledVp] = useState(null);
    // const [viewportHeight, setViewportHeight] = useState(0);
    const [pageHeight, setPageHeight] = useState(0);
  
    useLayoutEffect(() => {
      // setViewportHeight(window.innerHeight)
      setPageHeight(document.body.clientHeight)
      
      if (!ref.current) {
        return;
      }

      //calculations to factor in size of different viewports
      const rect = ref.current.getBoundingClientRect();
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const offsetTop = rect.top + scrollTop;
      const viewportHeight = window.innerHeight;
      const pageHeight = document.body.clientHeight;
      const a = pageHeight / viewportHeight;
      const b = pageHeight / (a - 1);
      const c = offsetTop / viewportHeight; 
      const startValue = (b * c) / pageHeight;
      const endValue = ((rect.height * (a/(a-1))) / pageHeight) + startValue
      
      setStart(startValue);
      setEnd(endValue);
      setScaledVp(b);

      // console.log('scaledVp: ' + scaledVp)
      // console.log('pageHeight: ' + pageHeight)

      setStartVpBottom(startVpTop - (scaledVp / pageHeight)) //start animation as soon as component enters viewport

    });
  
    return { startVpTop, startVpBottom, endVpTop, scaledVp, pageHeight };
    // start and end values amrk the position of the component beginning and end based on a scale from 0-1. 
    // 0 is the very beginning of the page, 1 the end.

    // start: component touches top edge of viewport
    // end: end of component touches top edge of viewport
    // scaledVp: useful for calculations for animations based on the viewport (in px)
    // pagehIeght: height of the whole page (in px)
  }

  export default useRefScrollProgress;