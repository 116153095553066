import {
  Box,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import ParallaxImg from "./ParallaxImg";
export default function HeroSection({
  children,
  bgColor,
  mediaOpacity,
  video,
  minH,
  justifyContent,
  mediaSrc,
  maxH,
  alt,
  parallaxOffsetEnd,
  parallaxOffsetStart
}) {
  const [viewportHeight, setViewportHeight] = useState(0);
  const [pageHeight, setPageHeight] = useState(0)
  useEffect(() => {
    setViewportHeight(window.innerHeight)
    setPageHeight(document.body.clientHeight)
  })
  return (
    <Grid as='section' templateColumns="repeat(1, 1fr)" maxH={maxH}>
      <GridItem
        background={bgColor}
        gridRow="1"
        gridColumn="1"
        zIndex="1"
      >
        <Box h='100%'>
          {video ? (
            <video
              muted
              autoPlay
              preload="auto"
              loop
              style={{
                minHeight: minH,
                width: 100 + "%",
                height: 100 + '%',
                objectFit: "cover",
                opacity: mediaOpacity
              }}
              alt={alt}
            >
              <source src={mediaSrc} type="video/mp4" />
            </video>
          ) : (
            // <GImage
            //   minH={minH}
            //   width='100%'
            //   height='100%'
            //   objectFit='cover'
            //   maxHeight={maxH}
            //   src={mediaSrc}
            //   opacity={mediaOpacity}
            //   alt={alt}
            //   css={{
            //     'img': {
            //       objectPosition: 'right'

            //     }
            //   }}
            // />
            <Box minH={minH} maxHeight={maxH}>
              <ParallaxImg
                minH={minH}
                width='100%'
                height='100%'
                objectFit='cover'
                maxHeight={maxH}
                imgSrc={mediaSrc}
                opacity={mediaOpacity}
                imgAlt={alt}
                parallaxOffsetEnd={parallaxOffsetEnd}
                parallaxOffsetStart={parallaxOffsetStart}
                css={{
                  'img': {
                    objectPosition: '80% 0',
                  },
                }
                }
              />
            </Box>
          )}
        </Box>
      </GridItem>
      <GridItem gridRow="1" gridColumn="1" zIndex="2" display='flex' h='100%' flexDirection={'column'} justifyContent={justifyContent}>
        {children}
      </GridItem>
    </Grid>
  );
}
