import React, { useState, useEffect } from 'react'

import ScrollAnimationWrapper from './ScrollAnimationWrapper'
import { Box } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import ParallaxImgInner from './ParallaxImgInner'

const MotionBox = motion(Box)
const ParallaxImg = ({
  imgSrc,
  imgAlt,
  parallaxOffsetStart,
  parallaxOffsetEnd,
  ...imgProps
}) => {
  return (
    <ScrollAnimationWrapper sticky={false} wrapperHeight={'auto'}>
      {(
        AnimationValue,
        SetScrollProgressKeyPointStartVpTop,
        SetScrollProgressKeyPointStartVpBottom,
        SetScrollProgressKeyPointTopEdgeViewport
      ) => (
        <MotionBox>
          <ParallaxImgInner
            imgSrc={imgSrc}
            alt={imgAlt}
            parallaxOffsetStart={parallaxOffsetStart}
            parallaxOffsetEnd={parallaxOffsetEnd}
            AnimationValue={AnimationValue}
            SetScrollProgressKeyPointStartVpBottom={SetScrollProgressKeyPointStartVpBottom}
            {...imgProps}
          />
        </MotionBox>
      )}
    </ScrollAnimationWrapper>
  )
}

export default ParallaxImg