import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image'
import { StaticQuery, graphql } from 'gatsby';
import { Image } from '@chakra-ui/react'
import { motion } from 'framer-motion'

const MotionImg = motion(Image)
function renderImage(file, alt, restProps) {
    if(file.node.extension === 'svg') {
      return <Image alt={alt} src={file.node.publicURL} {...restProps} />
    }else{
      return <Image as={GatsbyImage} image={file.node.childImageSharp.gatsbyImageData} alt={alt} {...restProps} /> 
    }
  
}
const GImage = function ({src, alt, ...restProps}) {
  return <StaticQuery
    query={graphql`
      query {
      images: allFile(filter:{ extension: { regex: "/jpeg|jpg|png|gif|webp|svg/"}}) {
      edges {
        node {
          extension
          relativePath
          publicURL
          childImageSharp {
            gatsbyImageData(
                placeholder: BLURRED
              )
            }
          }
        }
      }
    }
    `}
    render={({ images }) => renderImage(images.edges.find(image => image.node.relativePath === src), alt, restProps)}
  />
}
export default GImage;
